<template>
  <UiPopup
    :model-value="modelValue"
    title="Failed WhatsApp connection"
    description="The phone number you want to connect doesn’t match the user's profile (corporate) number. Please connect WhatsApp to the ThriveState number listed in the profile."
    size="small"
    primary-button-text="Close"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="emits('update:modelValue', false)"
  />
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})
</script>

<style scoped></style>
